<template>
	<div class="study">
		<van-nav-bar fixed left-arrow @click-left="clickNavBack" :border="false" z-index="11"/>
		<div id="cc_player" class="cc_player fixed"></div>
		<div class="cc_player"></div>
		<div class="main" style="position:relative;">
			<studyTitle
				:info="playVideo"
				:loadingFace="loadingFace"
				@handleFace="handleFace"
				@imgChange="imgChange"
			/>
			<div class="content">
				<studySidebar
					:list="videoTree"
					:cur="playVideoChapter"
					:act="orderChapterLocal"
					:actVideo="playVideo"
					@clickToggleChapter="clickToggleChapter"
				/>
				<studyList
					:chapter="playVideoChapter"
					:current="playVideo"
					:mustVideo="orderVideoLocal"
					:isPlayState="isPlayState"
					:videoList="videoList"
					@clickVideo="clickVideo"
				/>
			</div>
			<div class="sign-wrap" v-if="showPopover">
				<div>
					<img src="../../assets/v-tips.png" alt="">
				</div>
				<div class="sign-text">
					<div v-for="(item,index) in issign" :key="index" class="text-box" @click="signtip(index)">
						<div class="text-btns" :class="iscolor==index?'btn-color':''"></div>
						<span class="text-text">{{ item.text }}</span>
					</div>
				</div>
			</div>
		</div>
		<input v-show="false" ref="imgChange" @change="imgChange($event)" type="file" accept="image/*" capture="user">
	</div>
</template>

<script>
import studyTitle from './components/title'
import studySidebar from './components/sidebar'
import studyList from './components/list'
import {viewLogPush, getPlanInfo, lastWatchLength} from "../../api/user"
import {Dialog} from "vant";
import store from "../../store";
import Compressor from 'compressorjs';

export default {
	name: 'study',
	data: () => ({
		loadingFace: false, 		// 人脸状态
		videoTree: [], 				// 视频数据结构树
		videoList: [], 				// 所有可播放视频节点的数组集合
		orderVideoLocal: {}, 		// 顺序播放视频实际位置
		orderChapterLocal: {}, 		// 顺序播放视频的章节实际位置
		playVideo: {}, 				// 正在播放的视频
		playVideoChapter: [], 		// 当前播放视频所属的章节
		isPlayState: false, 		// 当前是否正在播放
		player: null,
		middleFace: false,
		faceRecognitionType: null,
		submitStudyTimer: null, 	// 定时器
		playModeType: '0', 			// 学习计划-连续播放状态
		provinceCode: null,			// 学习计划-省份代码
		categoryId: null,			// 学习计划-类别体系id
		organizationId: null,		// 所属机构
		showPopover: true,
		issign: [{
				text: '我已知晓'
			}, {
				text: '别再提示'
			}
		],
		iscolor: -1,
		orderVideoLocalIndex: 0,
		timer: null,
		showRateBtn: 0	//倍速播放：0不显示/1显示
	}),
	components: {studyTitle, studySidebar, studyList},
	created() {
		this.getPlanInfo()
		this.bindCCEvent()
		/** 清除签到人脸 **/
		this.$store.commit('cache/set_face', {key: 'signInPhoto', value: ''})
		/** 加载视频列表 **/
		this.loadVideoList()
		if (localStorage.getItem('showPopover')) {
			this.showPopover = JSON.parse(localStorage.getItem('showPopover'))
		} else {
			this.showPopover = true;
		}
	},
	destroyed() {
		this.player && this.player.destroy();
	},
	computed: {
		userid() {
			return this.$store.state.user.userid
		},
		isUseJSPlayPhoto() {
			if (this.$store.state.cache.screen.wx) return true
			if (this.$route.query.state === 'js') return false
			// if (sessionStorage.getItem('anhuihefei') === '1') {
			//	return false
			// }
			if (this.$store.state.cache.single && this.$store.state.cache.single.state === 'single') return true
			return true
		},
		appno() {
			return this.$store.state.app.appno
		}
	},
	mounted() {
		const _this = this
		/** 绑定拍照 JS 回调事件 **/
		window['receivePhotoResult'] = (base64) => {
			this.receivePhotoResult(base64);
		}
		window.getCameraData = (base64) => {
			console.log('uni-app.study 拍照回调', base64.substring(0, 10))
			this.receivePhotoResult(base64);
		}
		if (this.$store.state.cache.screen.wx) {
			this.uploading = true
			this.$api.wxConfig().then(res => {
				if (res.code === 0) {
					const data = res.data
					wx.config({
						debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: data.appId, // 必填，公众号的唯一标识
						timestamp: data.timestamp, // 必填，生成签名的时间戳
						nonceStr: data.nonceStr, // 必填，生成签名的随机串
						signature: data.signature,// 必填，签名
						jsApiList: ['chooseImage', 'uploadImage'] // 必填，需要使用的JS接口列表
					})
				}
			})
			wx.ready(() => {
				_this.uploading = false
			})
			wx.error(err => {
				_this.uploading = false
				console.log('微信config出错 ===', err)
				this.$utils.dialog.alert(`微信config失败：${err.errMsg}`)
			})
		}
	},
	methods: {
		// 获取学习计划
		getPlanInfo() {
			//const _this = this
			getPlanInfo(this.userid).then(res => {
				this.playModeType = res.playModeType;
				this.categoryId = res.categoryId;
				this.provinceCode = res.provinceCode;
				this.faceRecognitionType = res.faceRecognitionType;
				this.organizationId = res.belongOrganization;
				// 常州教练员允许倍速
				// if (',119,'.indexOf(',' + _this.categoryId + ',') !== -1 && _this.provinceCode === '320000') {
				// 	this.showRateBtn = 1;
				// }
			})
		},
		// 点击回退
		clickNavBack() {
			const _this = this
			this.$api.checkSignOut(this.$route.query.signId).then(res => {
				if (res.code === 0) {
					if (res.data.canBeSignOut == 1) {
						Dialog.confirm({
							title: '温馨提示',
							message: '如您退出学习，请先点击签退按钮签退，否则会影响学时送审。',
							theme: 'round-button',
							confirmButtonColor: store.state.app.theme.colorMain,
							cancelButtonColor: '#D4D4D4',
							confirmButtonText: '签退',
							width: 290,
						}).then((res) => {
							if (res === 'confirm') {
								if (_this.isUseJSPlayPhoto) {
									_this.handleFace()
								} else {
									_this.$refs.imgChange.click()
								}
							}
						}).catch((err) => {
							if (err === 'cancel') {
								// _this.$router.push('/train')
							}
						})
					} else {
						_this.$router.push('/train')
					}
				}
			})
		},
		/** 绑定 cc 全局事件 **/
		bindCCEvent() {
			window.onCCH5PlayerLoaded = this.onCCH5PlayerLoaded
			window.on_CCH5player_play = this.on_CCH5player_play
			window.on_CCH5player_pause = this.on_CCH5player_pause
			window.on_CCH5player_ended = this.on_CCH5player_ended
			window.on_CCH5player_ready = this.onPlayerReady
			window.on_player_volumechange = this.onPlayerVolumeChange
			window.on_switch_rate = this.on_switch_rate
		},
		/** 加载视频列表 **/
		loadVideoList() {
			this.$api.getVideoList({
				subject: this.$route.query.subject,
				studentId: this.userid,
				courseId: this.$route.query.courseid,
			}).then(res => {
				if (res.code === 0) {
					this.loadVideoListFormat(res.data)
				}
			})
		},
		clickToggleChapter(data) {
			this.playVideoChapter = data
		},
		on_switch_rate(vid, newRate){
			console("倍速回调测试",vid+","+newRate)
		},
		/** 格式化视频列表 **/
		loadVideoListFormat(data) {
			this.videoTree = []
			for (let i = 0, len = data.length; i < len; i++) {
				if (data[i].type === 3) {
					this.videoList.push(data[i])
				}
				if (data[i].parentPkId === 0) {
					this.videoTree.push(data[i])
				} else {
					this.videoTree = this.loadVideoListFormatRecursive(this.videoTree, data[i])
				}
			}
			if (JSON.stringify(this.playVideo) === '{}') {
				this.playVideo = this.videoList[this.videoList.length - 1]
				this.playVideoChapter = this.videoTree[this.videoTree.length - 1]
				this.loadCCVideo(this.playVideo)
			}
		},
		loadVideoListFormatRecursive(list, item) {
			for (let i = 0, len = list.length; i < len; i++) {
				if (list[i].basePkId === item.parentPkId) {
					if (!list[i].children) list[i].children = []
					list[i].children.push(item)
					if (JSON.stringify(this.orderVideoLocal) === '{}') {
						if (item.watchedFlag === '0') {
							this.orderVideoLocal = item
							this.playVideo = item
							this.playVideoChapter = this.videoTree.find(item => item.basePkId === list[i].parentPkId)
							this.orderChapterLocal = this.videoTree.find(item => item.basePkId === list[i].parentPkId)
							this.loadCCVideo(item)
						}
					}
					break
				} else if (list[i].children) {
					list[i].children = this.loadVideoListFormatRecursive(list[i].children, item)
				}
			}
			return list
		},
		/**
		 * @param { number | string } wait： 执行时间 (number) 是否停止定时器 (string: clear)
		 */
		timingSubmitStudyThrittle(wait) {
			const _this = this;
			if (typeof wait === 'string' && wait === 'clear') {
				if (this.submitStudyTimer) {
					clearTimeout(_this.submitStudyTimer)
					this.submitStudyTimer = null
				}
			} else if (typeof wait === 'number') {
				if (!this.submitStudyTimer) {
					this.submitStudyTimer = setTimeout(() => {
						_this.timingSubmitStudy();
						_this.submitStudyTimer = null
						_this.timingSubmitStudyThrittle(wait)
					}, wait)
				}
			}
		},
		// 提交学时，20S一次，暂停时、播放结束时，均需保存学时
		timingSubmitStudy(/*...data */) {
			const _this = this;
			viewLogPush({
				chapterId: this.playVideo.basePkId /* '章节ID' */,
				courseId: this.$route.query.courseId /*'课程ID'*/,
				planId: this.$store.state.user.planid /* '学员计划ID'*/,
				signId: this.$route.query.signId /* '签到ID'*/,
				studentId: this.userid /* '学员ID'*/,
				videoId: this.playVideo.videoId /* '视频ID' */,
				videoLength: this.playVideo.video.videoDuration /* '视频总长度' */,
				watchLength: this.player.getPosition() || 0 /* '当前观看长度' */
			}).then(res => {
				if (res.signTime) {
					this.player.pause();
					this.player.normalScreen();
					_this.middleFace = true;
					this.$utils.dialog.alert('需要进行人脸识别，请端正手机拍照！（不要躺着，手机拿正拍照）', () => {
						// 此处 调用APP 摄像头方法
						if (_this.isUseJSPlayPhoto) {
							_this.handleFace()
						} else {
							_this.$refs.imgChange.click()
						}
					});
					/* 江西初学3分钟内 */
					if ( _this.$store.state.user.planid == 151 ) _this.delayEndTheStudyProc();
				}
			}).catch(() => {
				_this.player.pause();
				clearTimeout(_this.submitStudyTimer)
			})
		},
		delayEndTheStudyProc() {
			this.timer = setTimeout(() => {
				this.signBackNoPhoto();
			}, 180000);
		},
		destroyedTimer() {
			if (this.timer !== null) {
				clearTimeout(this.timer);
			}
		},
		/** 点击切换播放的视频 **/
		clickVideo(video) {
			this.orderVideoLocal = this.videoList[this.orderVideoLocalIndex]
			if (video.watchedFlag === '0' && video.basePkId !== this.orderVideoLocal.basePkId) {
				this.$utils.dialog.alert('请按顺序进行学习')
			} else {
				this.playVideo = video
				if (this.playModeType === '1') {
					this.player.changeVideo(video.video.videoCcid)
				} else {
					this.loadCCVideo(video)
				}
			}
		},
		onCCH5PlayerLoaded(error) {
			console.log(123, error)
		},
		on_CCH5player_play() {
			console.log('on_CCH5player_play')
			if (this.middleFace === true) {
				this.timingSubmitStudy();
				return
			}
			this.isPlayState = true
			this.recordStudyTime(0)
			// 开启节流 20S 一次
			this.timingSubmitStudyThrittle(60000)
		},
		on_CCH5player_pause() {
			console.log('on_CCH5player_pause')
			this.timingSubmitStudyThrittle('clear')
			this.isPlayState = false
		},
		on_CCH5player_ended() {
			console.log('on_CCH5player_ended')
			this.isPlayState = false
			this.timingSubmitStudyThrittle('clear')
			this.recordStudyTime(this.playVideo.video.videoDuration, 'next')
			if (localStorage.getItem('videoPlayNums')) {
				let times = parseInt(localStorage.getItem('videoPlayNums')) + 1;
				localStorage.setItem('videoPlayNums', '' + times + '');
			}
		},
		onPlayerReady(obj) {
			console.log('onPlayerReady')

			lastWatchLength({
				studentId: this.userid,
				videoId: this.playVideo.videoId
			}).then(res => {
				console.log("准备播放咯",res)
				this.player.jumpToTime(res.watchLength)
			})

			var ccVideo = document.getElementById("cc_"+obj.vid);
			ccVideo.addEventListener("ratechange", myFunction);
			const _this = this;
			function myFunction() {
				if (_this.showRateBtn === 1) {
					return;
				}
				if (ccVideo.playbackRate > 1) {
				ccVideo.playbackRate = 1;
				_this.player.pause();
				console.log("暂停")
				_this.player.normalScreen();
				console.log("退出全屏")
				setTimeout(() => {
					_this.$dialog.alert({
						message: '抱歉，视频学习过程中不允许倍速播放！',
						theme: 'round-button',
						confirmButtonColor: '#fca142',
						width: 290,
						});
					}, 1000)
				}
			}
			this.orderVideoLocalIndex = this.videoList.indexOf(this.orderVideoLocal);
		},
		onPlayerVolumeChange() {
			console.log('onPlayerVolumeChange')
		},
		/** 记录学时 **/
		recordStudyTime(watchLength, type = '') {
			// 只有当前播放的视频跟要学习的视频是同一个视频的时候，才会记录学时
			if (this.playVideo.basePkId === this.orderVideoLocal.basePkId) {
				this.$api.watchVideo({
					studentId: this.userid,
					signId: this.$route.query.signId,
					videoId: this.playVideo.videoId,
					chapterId: this.playVideo.basePkId,
					courseId: this.$route.query.courseid,
					watchLength,
					videoLength: this.playVideo.video.videoDuration
				}).then(res => {
					if (res.code === 0) {
						if (type === 'next') this.loadNextVideo()
					}
				})
			} else {
				this.orderVideoLocal = {};
				if (type === 'next') this.loadVideoList();
			}
		},
		/** 加载下一个播放的视频节点 **/
		loadNextVideo() {
			// orderChapterLocal
			const curIndex = this.videoList.findIndex(item => item.basePkId === this.orderVideoLocal.basePkId)
			this.orderVideoLocal.watchedFlag = '1'
			if (curIndex < (this.videoList.length - 1)) {
				this.orderVideoLocal = this.videoList[curIndex + 1]
				this.playVideo = this.videoList[curIndex + 1]
				for (let i = 0, len = this.videoTree.length; i < len; i++) {
					const chapterIndex = this.videoTree[i].children.findIndex(item => item.basePkId === this.playVideo.parentPkId)
					if (chapterIndex > -1) {
						this.videoTree[i].children[chapterIndex].children[this.playVideo.chapterIndex - 1] = this.orderVideoLocal
						if (this.videoList[curIndex + 1].chapterIndex === 1) {
							this.playVideoChapter = this.videoTree[i]
							this.orderChapterLocal = this.videoTree[i]
						}
						break
					}
				}
				if (this.playModeType === '1') {
					this.player.changeVideo(this.videoList[curIndex + 1].video.videoCcid)
				} else {
					this.loadCCVideo(this.videoList[curIndex + 1])
				}
			}
		},
		/** 加载CC视频 **/
		loadCCVideo(data) {
			this.reviewer = document.getElementById('cc_player');
			this.player = window.createCCH5Player({
				'vid': data.video.videoCcid,
				'siteid': 'D9CDA78D56C216BD',
				'autoStart': 'true',
				'showRateBtn': this.showRateBtn === 0 ? 'false' : 'true',
				'banDrag': (data.watchedFlag == "1" || this.showRateBtn === 1) ? 'false' : 'true',
				'playtype': 1,
				// 'progressbar_enable': 1,
				'useX5Play': 'true', // 同层播放
				// rate_array: [1],
				'width': "100%",
				'height': "210",
				'parentNode': this.reviewer
			});
		},
		/** 拍照区域 **/
		handleFace() {
			const _this = this
			if (this.faceRecognitionType === null) {
				_this.$utils.dialog.alert('手速太快休息一会儿~');
				return;
			}
			if (this.faceRecognitionType === '0') {
				this.signBackNoPhoto();
			}
			if (this.loadingFace) return
			if (this.$store.state.cache.screen.wx) {
				wx.chooseImage({
					count: 1, // 默认9
					sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
					sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
					success: function (res) {
						wx.uploadImage({
							localId: res.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
							isShowProgressTips: 1, // 默认为1，显示进度提示
							success: function (uploadRes) {
								_this.loadingFace = true
								_this.$api.wxCompareFace({
									mediaId: uploadRes.serverId,
									signId: _this.$route.query.signId,
									chapterId: _this.playVideo.basePkId
								}).then((res) => {
									_this.loadingFace = false
									if (res.code === 0) {
										if (_this.middleFace) { // 如果是中间验证人脸，验证完成之后，还原为false，如果是false，就走签退
											_this.middleFace = false
											_this.$utils.dialog.alert('验证成功，请继续学习', () => {
												console.log("视频播放")
												_this.player.play();
												console.log("比对通过，调用jumpToTime")
												console.log('当前视频秒数',_this.player.getPosition());
												console.log('jumpToTime');
												_this.player.jumpToTime(_this.player.getPosition()-2);
											})
										} else {
											_this.signBack({
												mediaId: uploadRes.serverId
											})
										}
									}
								}).catch(() => {
									_this.loadingFace = false
								})
							},
							fail: function() {
								_this.loadingFace = false
								_this.$utils.dialog.alert('上传照片失败，因微信使用内存不足，建议关闭浮窗播放或重启手机！');
							}
						})
					},
					fail: function() {
						_this.loadingFace = false
						_this.$utils.dialog.alert('拍照失败，微信使用内存不足，建议关闭浮窗播放或重启手机！');
					}
				})
			} else {
				if (this.player && !this.middleFace) {
					this.player.pause();
				}
				if (this.$store.state.cache.screen.isAndroid) {
					if (uni) {
						uni.postMessage({
							data: {
								action: 'setCamera'
							}
						});
						//return;
					}
					if (window.Android_JS && window.Android_JS.androidPhoto) {
						window.Android_JS.androidPhoto();
					}
				}
				if (this.$store.state.cache.screen.isIos) {
					if (uni) {
						uni.postMessage({
							data: {
								action: 'setCamera'
							}
						});
						//return;
					}
					if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosPhoto) {
						window.webkit.messageHandlers.iosPhoto.postMessage(null);
					}
				}
			}
		},
		imgChange(event) {
			if (this.loadingFace) return
			let file = event.target.files[0];
			const _this = this;
			if (this.player) {
				this.player.pause();
			}
			new Compressor(file, {
				quality: 0.9,
				maxWidth: 413,
				maxHeight: 626,
				convertTypes: ['image/jpeg'],
				convertSize: 1000000,
				success(result) {
					console.log('study照片压缩成功');
					let reader = new FileReader();
					reader.readAsDataURL(result);
					reader.onload = (event) => {
						console.log('study执行receivePhotoResult');
						_this.receivePhotoResult(event.target.result)
					}
				},
				error(err) {
					console.log(err);
					_this.$utils.dialog.alert('照片压缩时出错，请重拍');
				}
			});
		},
		receivePhotoResult(base64) {
			const _this = this;
			this.loadingFace = true
			if (',102,104,105,106,168,'.indexOf(',' + _this.categoryId + ',') !== -1 && _this.provinceCode === '360000') {
				// 江西初学
				if (_this.middleFace) { // 如果是中间验证人脸，验证完成之后，还原为false，如果是false，就走签退
					this.destroyedTimer();
					this.$api.compareFace({
						signId: _this.$route.query.signId,
						photo: base64,
						chapterId: _this.playVideo.basePkId
					}).then((res) => {
						if (res.code === 0) {
							_this.middleFace = false
							_this.$utils.dialog.alert('验证成功，请继续学习', () => {
								_this.player.play();
								_this.player.jumpToTime(_this.player.getPosition() - 2);
							})
						}
						_this.loadingFace = false
					}).catch(() => {
						_this.loadingFace = false
					})
				} else {
					_this.signBack({base64})
				}
			} else {
				this.$api.compareFace({
					signId: _this.$route.query.signId,
					photo: base64,
					chapterId: _this.playVideo.basePkId
				}).then((res) => {
					if (res.code === 0) {
						if (_this.middleFace) { // 如果是中间验证人脸，验证完成之后，还原为false，如果是false，就走签退
							_this.middleFace = false
							_this.$utils.dialog.alert('验证成功，请继续学习', () => {
								_this.player.play();
								console.log('视频播放API');
								console.log("比对通过，调用jumpToTime")
								console.log('当前视频秒数', _this.player.getPosition());
								_this.player.jumpToTime(_this.player.getPosition() - 2);
								console.log('jumpToTime及跳转时间', _this.player.getPosition() - 2);
							})
						} else {
							_this.signBack({base64})
						}
					}
					_this.loadingFace = false
				}).catch(() => {
					_this.loadingFace = false
				})
			}
		},
		/** 签退 **/
		signBack({base64 = '', mediaId = ''}) {
			const _this = this
			this.$api.signOut(this.$route.query.signId, base64, mediaId).then((res) => {
				if (res.code === 0) {
					if (_this.organizationId === '57') {
						_this.$api.checkIfLastVideo(_this.$route.query.signId, _this.playVideo.videoId).then((res2) => {
							let msg = '签退成功！';
							if (res2.code === 0) {
								msg = '签退成功！本部分课程已学习完毕，请随时关注 [学时审核结果]，遇到审核学时不足时，请点击 [开始学习] 按钮重新从头学习，直至补足学时为止。原学时变成重置学时状态。';
							}
							_this.$utils.dialog.alert(msg, () => {
								_this.$router.replace('/train')
							})
							return
						})
						return
					}
					_this.$utils.dialog.alert('签退成功', () => {
						_this.$router.replace('/train')
					})
				}
			})
		},
		/** 签退不带照片 **/
		signBackNoPhoto() {
			const _this = this
			Dialog.confirm({
				title: '温馨提示',
				theme: 'round-button',
				message: '是否确认签退',
				cancelButtonColor: this.$store.state.app.theme.colorMain,
				confirmButtonColor: '#ee0a24',
				cancelButtonText:'确认',
				confirmButtonText:'取消',
			}).then(() => {
			}).catch(() => {
				this.$api.signOutNoPhoto(this.$route.query.signId).then((res) => {
					if (res.code === 0) {
						_this.$utils.dialog.alert('签退成功', () => {
							window.location.href = sessionStorage.getItem("loginSteam")
							window.location.reload()
						})
					}
				})
			});
		},
		signtip(index) {
			this.iscolor = index;
			if (index === 0) {
				this.showPopover = false;
			} else {
				this.showPopover = false;
				localStorage.setItem('showPopover', JSON.stringify(this.showPopover));
			}
		},
	}
}
</script>

<style scoped>
.sign-wrap {
	position: absolute;
	top: 31px;
	right: 44px;
	width: 60%;
	z-index: 9999;
	/* position: relative; */
}

.sign-wrap img {
	width: 100%;
}

.sign-wrap .sign-text {
	width: 100%;
	display: flex;
	margin: -30px 0 0 0;
	font-size: 12px;
}

.sign-wrap .sign-text .text-box {
	display: flex;
	margin-left: 6px;
}

.sign-wrap .sign-text .text-box .text-btns {
	width: 10px;
	height: 10px;
	background-color: #fff;
	border-radius: 5px;
	margin: 4px 4px 0 0;
}

.sign-wrap .sign-text .text-box .btn-color {
	background-color: #ffac1b;
}

.sign-wrap .sign-text .text-box .text-text {
	border-bottom: 1px solid #000;
}
</style>

